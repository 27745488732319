import React from 'react'
import styles from './styles.module.css'
import { useLocation } from 'react-router';


const TipsDetail = () => {
  const location = useLocation();
  const item = location.state; 

  return (<>
  <div className={styles.imageContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.wrapper}>
            <img className={styles.image} src={item.image}  alt={item.title}/>
          </div>
        </div>
    </div>
    <div className={styles.tipsContainer}>
        <div className={styles.tipsInner}>
        <h1 className={styles.header}> {item.title} </h1>
        <p>{item.description.info}</p>
          <h2>{item.description.firstSubTitle}</h2>
          <p>{item.description.firstInfo}</p>
          <br />
          <h2>{item.description.secondSubTitle}</h2>
          <p>{item.description.secondInfo}</p>
          <br />
          <h2>{item.description.thirdSubTitle}</h2>
          <p>{item.description.thirdInfo}</p>
          <br />
          <h2>{item.description.fourthSubTitle}</h2>
          <p>{item.description.fourthInfo}</p>
          <br />
          <h2>{item.description.fifthSubTitle}</h2>
          <p>{item.description.fifthInfo}</p>
          <br />
          <p>{item.description.summary}</p>
        </div>
    </div>
  </>
    
  )
}

export default TipsDetail