import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { HamburgerMenuClose, HamburgerMenuOpen } from "./Icons";
//import { logoImage } from "./Logo";
import logoImage from "../../assets/logo.png";

const navMenuItems = [
  { url: "/stadning", title: "Städning" },
  { url: "/flytt", title: "Flytt" },
  // { url: "/tradgard", title: "Trädgård" },
  // { url: "/fler", title: "Fler tjänster" },
  { url: "/foretag", title: "Företag" },
  { url: "/om-oss", title: "Om Oss" },
];

function Navi() {
  const [click, setClick] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 80) {
        setIsScrolled(true);
      }else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  const handleClick = () => {
    setClick(!click);
    window.scrollTo({top:0, behavior:'smooth'})
  };
  return (
    <>
    {/* navbar scroll gecisi icin className={isScrolled ? 'navbar navbarWhite' : 'navbar navbarBg'} */}
      <nav className={isScrolled ? 'navbar navbarWhite' : 'navbar navbarWhite'} >
        <div className="nav-container">
          <NavLink onClick={handleClick} to="/" className="nav-logo">
            
              <img src={logoImage} alt="logo" className="logoImage" />
           
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {navMenuItems.map((item, index) => {
              return(
                <li key={index} className="nav-item">
              <NavLink
                to={item.url}
                activeclassname="active"
                className="nav-links"
                onClick={handleClick}
              >
                {item.title}
              </NavLink>
            </li>
              )
            })}
            
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgerMenuOpen />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgerMenuClose />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navi;
