import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hemstadnings: null,
  hemstadprices: [
    {
      limit: 49,
      price: [2849, 1549, 1249],
      priceWithPets: [3399, 1899, 1249],
    },
    {
      limit: 69,
      price: [3399, 1899, 1249],
      priceWithPets: [3929, 2249, 1249],
    },
    {
      limit: 99,
      price: [3929, 2249, 1249],
      priceWithPets: [4549, 2549, 1449],
    },
    {
      limit: 129,
      price: [4549, 2549, 1449],
      priceWithPets: [5149, 2849, 1629],
    },
    {
      limit: 159,
      price: [5149, 2849, 1629],
      priceWithPets: [5699, 3149, 1749],
    },
    {
      limit: 179,
      price: [5699, 3149, 1749],
      priceWithPets: [6249, 3449, 1899],
    },
    {
      limit: 199,
      price: [6249, 3449, 1899],
      priceWithPets: [6799, 3699, 2049],
    },
  ],
  storstadnings: null,
  storstadprices: [
    {
      limit: 50,
      price: 2295,
      priceWithPets: 2495,
    },
    {
      limit: 55,
      price: 2565,
      priceWithPets: 2765,
    },
    {
      limit: 65,
      price: 2737,
      priceWithPets: 2937,
    },
    {
      limit: 70,
      price: 2916,
      priceWithPets: 3116,
    },
    {
      limit: 75,
      price: 3123,
      priceWithPets: 3323,
    },
    {
      limit: 80,
      price: 3272,
      priceWithPets: 3472,
    },
    {
      limit: 90,
      price: 3623,
      priceWithPets: 3823,
    },
    {
      limit: 100,
      price: 3979,
      priceWithPets: 4179,
    },
    {
      limit: 105,
      price: 4186,
      priceWithPets: 4386,
    },
    {
      limit: 120,
      price: 4405,
      priceWithPets: 4605,
    },
    {
      limit: 140,
      price: 4715,
      priceWithPets: 4915,
    },
    {
      limit: 150,
      price: 5032,
      priceWithPets: 5232,
    },
    {
      limit: 160,
      price: 5359,
      priceWithPets: 5559,
    },
    {
      limit: 180,
      price: 5693,
      priceWithPets: 5893,
    },
    {
      limit: 200,
      price: 6021,
      priceWithPets: 6221,
    },
    {
      limit: 250,
      price: 7010,
      priceWithPets: 7210,
    },
    {
      limit: 300,
      price: 7590,
      priceWithPets: 7790,
    },
    {
      limit: 350,
      price: 8551,
      priceWithPets: 8751,
    },
    {
      limit: 400,
      price: 9114,
      priceWithPets: 9314,
    },
    {
      limit: 450,
      price: 9764,
      priceWithPets: 9964,
    },
    {
      limit: 500,
      price: 10339,
      priceWithPets: 10539,
    },
  ],
  flyttstadnings: null,
  flyttstadprices: [
    {
      limit: 50,
      price: 2595,
    },
    {
      limit: 60,
      price: 2890,
    },
    {
      limit: 65,
      price: 3185,
    },
    {
      limit: 70,
      price: 3480,
    },
    {
      limit: 75,
      price: 3775,
    },
    {
      limit: 80,
      price: 3875,
    },
    {
      limit: 90,
      price: 4170,
    },
    {
      limit: 100,
      price: 4465,
    },
    {
      limit: 110,
      price: 4715,
    },
    {
      limit: 120,
      price: 4965,
    },
    {
      limit: 140,
      price: 5215,
    },
    {
      limit: 150,
      price: 5465,
    },
    {
      limit: 160,
      price: 5715,
    },
    {
      limit: 170,
      price: 5965,
    },
    {
      limit: 180,
      price: 6215,
    },
    {
      limit: 190,
      price: 6465,
    },
    {
      limit: 200,
      price: 6715,
    },
    {
      limit: 205,
      price: 6965,
    },
    {
      limit: 210,
      price: 7065,
    },
    {
      limit: 215,
      price: 7165,
    },
    {
      limit: 220,
      price: 7265,
    },
    {
      limit: 230,
      price: 7365,
    },
    {
      limit: 235,
      price: 7465,
    },
    {
      limit: 245,
      price: 7565,
    },
    {
      limit: 250,
      price: 7815,
    },
    {
      limit: 255,
      price: 8065,
    },
    {
      limit: 265,
      price: 8315,
    },
    {
      limit: 280,
      price: 8565,
    },
    {
      limit: 295,
      price: 8815,
    },
    {
      limit: 300,
      price: 9065,
    },
  ],
  fonsterputs: null,
  fonsterputsprices: {
    utanSprojs: {
      2: 45,
      4: 90,
      3: 45,
    },
    medSprojs: {
      2: 130,
      4: 170,
      3: 45,
    },
    overhangda: {
      2: 130,
      4: 170,
      3: 45,
    },
    balkong: {
      2: 600,
      4: 800,
      3: 0,
    },
  },
  avfallshamtningprices:{
    whole:{
      price: 2500,
    },
    half:{
      price: 1500,
    },
    oneFourth:{
      price: 800,
    },
    mini:{
      price: 500,
    },
  },
  loading: false,
  error: false,
  areaInputError: null,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
      state.error = false;
    },
    loadData: (state, { payload }) => {
      state.formData = payload;
      state.loading = false;
    },
    fetchFail: (state) => {
      state.loading = false;
      state.error = true;
    },
    setAreaInputError: (state, { payload }) => {
      state.areaInputError = payload;
    },
  },
});

export const { fetchStart, loadData, fetchFail, setAreaInputError } =
  formSlice.actions;

export default formSlice.reducer;
