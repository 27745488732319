import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Navi from "../components/navbar";
import Footer from "../components/footer";
import TipsDetail from '../components/tipsdetail';

// Lazy load your pages
const Home = lazy(() => import("../pages/Home"));
const Stadning = lazy(() => import("../pages/Stadning"));
const Flytt = lazy(() => import("../pages/Flytt"));
// const Tradgard = lazy(() => import("../pages/Tradgard"));
// const FlerTjanster = lazy(() => import("../pages/FlerTjanster"));
const Foretag = lazy(() => import("../pages/Foretag"));
const OmOss = lazy(() => import("../pages/OmOss"));
const Kontakt = lazy(() => import("../pages/Kontakt"));
const Rutavdrag = lazy(() => import("../pages/Rutavdrag"));
const VanligaFragor = lazy(() => import("../pages/VanligaFragor"));
const SummaryPage = lazy(() => import("../pages/SummaryPage"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage"));

const BokaHemstadning = lazy(() => import("../pages/bokning/BokaHemstadning"));
const BokaStorstadning = lazy(() => import("../pages/bokning/BokaStorstadning"));
const BokaFonsterputs = lazy(() => import("../pages/bokning/BokaFonsterputs"));
const BokaFlyttstadning = lazy(() => import("../pages/bokning/BokaFlyttstadning"));
const BokaFlytthjalp = lazy(() => import("../pages/bokning/BokaFlytthjalp"));
const BokaTrappstadning = lazy(() => import("../pages/bokning/BokaTrappstadning"));
const BokaAvfallshamtning = lazy(() => import("../pages/bokning/BokaAvfallshamtning"));
// const BokaHantverkshjalp = lazy(() => import("../pages/bokning/BokaHantverkshjalp"));
const BokaKontorstadning = lazy(() => import("../pages/bokning/BokaKontorsstadning"));
const BokaKontorsflytt = lazy(() => import("../pages/bokning/BokaKontorsflytt"));

const Hemstadning = lazy(() => import("../pages/subpages/Hemstadning"));
const Storstadning = lazy(() => import("../pages/subpages/Storstadning"));
const Flyttstadning = lazy(() => import("../pages/subpages/Flyttstadning"));
const Fonsterputs = lazy(() => import("../pages/subpages/Fonsterputs"));
const Flytthjalp = lazy(() => import("../pages/subpages/Flytthjalp"));
const Kontorsstadning = lazy(() => import("../pages/subpages/Kontorsstadning"));
const Kontorsflytt = lazy(() => import("../pages/subpages/Kontorsflytt"));
const Trappstadning = lazy(() => import("../pages/subpages/Trappstadning"));
// const Hantverkshjalp = lazy(() => import("../pages/subpages/Hantverkshjalp"));
const Avfallshamtning = lazy(() => import("../pages/subpages/Avfallshamtning"));


const CustomNavi = () => {
  const location = useLocation();

  const renderHeader = () => {
    if (location.pathname.includes("bokning")) {
      return null;
    }
    return <Navi />;
  };

  return renderHeader();
};

const CustomFooter = () => {
  const location = useLocation();

  const renderFooter = () => {
    if (location.pathname.includes("bokning")) {
      return null;
    }
    return <Footer />;
  };

  return renderFooter();
};

function AppRouter() {
  return (
    <>
      <Router>
        <CustomNavi />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* Main service */}
            <Route path="/" element={<Home />} />
            <Route path="/stadning" element={<Stadning />} />
            <Route path="/flytt" element={<Flytt />} />
            {/* <Route path="/tradgard" element={<Tradgard />} /> */}
            {/* <Route path="/fler" element={<FlerTjanster />} /> */}
            <Route path="/foretag" element={<Foretag />} />
            <Route path="/om-oss" element={<OmOss />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/rutavdrag" element={<Rutavdrag />} />
            <Route path="/faq" element={<VanligaFragor />} />
            <Route path="/summary/:address" element={<SummaryPage />} />
            <Route path="/tips/:detail" element={<TipsDetail />} />

            {/* Sub service */}
            <Route path="/hemstadning" element={<Hemstadning />} />
            <Route path="/storstadning" element={<Storstadning />} />
            <Route path="/flyttstadning" element={<Flyttstadning />} />
            <Route path="/fonsterputs" element={<Fonsterputs />} />
            <Route path="/flytthjalp" element={<Flytthjalp />} />
            <Route path="/kontorsstadning" element={<Kontorsstadning />} />
            <Route path="/kontorsflytt" element={<Kontorsflytt />} />
            <Route path="/trappstadning" element={<Trappstadning />} />
            {/* <Route path="/hantverk" element={<Hantverkshjalp />} /> */}
            <Route path="/avfallshamtning" element={<Avfallshamtning />} />

            {/*Bokning*/}
            <Route path="/bokning/hemstadning" element={<BokaHemstadning />} />
            <Route path="/bokning/storstadning" element={<BokaStorstadning />} />
            <Route path="/bokning/fonsterputs" element={<BokaFonsterputs />} />
            <Route path="/bokning/flyttstadning" element={<BokaFlyttstadning />} />
            <Route path="/bokning/flytthjalp" element={<BokaFlytthjalp />} />
            <Route path="/bokning/trappstadning" element={<BokaTrappstadning />} />
            <Route path="/bokning/avfallshamtning" element={<BokaAvfallshamtning />} />
            {/* <Route path="/bokning/hantverk" element={<BokaHantverkshjalp />} /> */}
            <Route path="/bokning/kontorsstadning" element={<BokaKontorstadning />} />
            <Route path="/bokning/kontorsflytt" element={<BokaKontorsflytt />} />

            {/* Not Found */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <CustomFooter />
      </Router>
    </>
  );
}

export default AppRouter;
