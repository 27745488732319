import React from 'react';
import styles from './styles.module.css';

//import logo from '../../assets/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';


const goUp = () => {
  window.scrollTo({top:0, behavior:'smooth'});
};

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <nav className={styles.footerSection}>
          <ul className={styles.footerLinks}>
            <li className={styles.footerLinksListItem}>
              <div className={styles.headerWrapper}>
              <h3 className={styles.header}>Våra tjänster</h3>
              </div>
              <ul className={styles.footerLinksWrapper}>
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/stadning">Allt för ditt hem</NavLink></li>
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/flytt">Allt för din flytt</NavLink></li>
                {/* <li className={styles.footerLinkItem}><a className={styles.footerLink} href="/tradgard">Allt för din trädgård</a></li> */}
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/foretag">Allt för ert företag</NavLink></li>
              </ul>
            </li>

            <li className={styles.footerLinksListItem}>
            <div className={styles.headerWrapper}>
              <h3 className={styles.header}>Vi är Flytt och Ren</h3>
              </div>
              <ul className={styles.footerLinksWrapper}>
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/om-oss">Om Oss</NavLink></li>
              </ul>
            </li>

            <li className={styles.footerLinksListItem}>
            <div className={styles.headerWrapper}>
              <h3 className={styles.header}>Mer information</h3>
              </div>
              <ul className={styles.footerLinksWrapper}>
                
                {/* <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/tips">Tips och inspiration</NavLink></li> */}
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/rutavdrag">Om rutavdraget</NavLink></li>
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/faq">FAQ</NavLink></li>
              </ul>
            </li>

            <li className={styles.footerLinksListItem}>
            <div className={styles.headerWrapper}>
              <h3 className={styles.header}>Kontakta Oss</h3>
              </div>
              <ul className={styles.footerLinksWrapper}>
                <li className={styles.footerLinkItem}><NavLink className={styles.footerLink} onClick={goUp} to="/kontakt">Kontakta oss</NavLink></li>
              </ul>
            </li>
          </ul>
        </nav>
        <nav className={styles.socialMediaSection}>
          <ul className={styles.footerSocial}>
            <li className={styles.footerSocialIcon}><a className={styles.footerSocialLink} href="https://www.facebook.com/flyttochren" target="_blank" rel="noopener noreferrer">
              <div>
                <FontAwesomeIcon className={styles.socialIcon} icon={faFacebookF}/>
              </div>
              <span>Facebook</span>
              </a></li>
            <li className={styles.footerSocialIcon}><a className={styles.footerSocialLink} href="https://www.instagram.com/flyttochren" target="_blank" rel="noopener noreferrer">
              <div>
              <FontAwesomeIcon className={styles.socialIcon} icon={faInstagram}/>
              </div>
              <span>Instagram</span>
              </a></li>
            <li className={styles.footerSocialIcon}><a className={styles.footerSocialLink} href="https://www.linkedin.com/flyttochren" target="_blank" rel="noopener noreferrer">
              <div>
              <FontAwesomeIcon className={styles.socialIcon} icon={faLinkedinIn}/>
              </div>
              <span>LinkedIn</span>
              </a></li>
            <li className={styles.footerSocialIcon}><a className={styles.footerSocialLink} href="https://twitter.com/flyttochren" target="_blank" rel="noopener noreferrer">
              <div>
              <FontAwesomeIcon className={styles.socialIcon} icon={faTwitter}/>
              </div>
              <span>Twitter</span>
              </a></li>
            <li className={styles.footerSocialIcon}><a className={styles.footerSocialLink} href="https://www.youtube.com/channel/flyttochren" target="_blank" rel="noopener noreferrer">
              <div>
              <FontAwesomeIcon className={styles.socialIcon} icon={faYoutube}/>
              </div>
              <span>Youtube</span>
              </a></li>
          </ul>
        </nav>
        <nav className={styles.infoSection}>
          <ul className={styles.footerInfoLinks} style={{color:"#5a5a5a", fontWeight:"600"}}>
           
            <li className={styles.footerInfoLink}>
              Helsingborggatan 66 252 28, Helsingborg
            </li>
            <li className={styles.footerInfoLink}>
              <a href={`tel:${process.env.REACT_APP_INFO_TEL}`} style={{textDecoration:"none", color:"#5a5a5a"}}><FontAwesomeIcon icon={faPhone}/>{process.env.REACT_APP_INFO_TEL}</a>
            </li>
            <li className={styles.footerInfoLink}>
             <a href="mailto:info@flyttochren.se" style={{textDecoration:"none", color:"#5a5a5a"}}> <FontAwesomeIcon icon={faEnvelope}/> info@flyttochren.se</a>
            </li>
          </ul>
        </nav>
        {/* <nav className={styles.infoSection}>
          <ul className={styles.footerInfoLinks}>
           
            <li className={styles.footerInfoLink}><NavLink className={styles.footerLinkInfo} onClick={goUp} to="/villkor">Allmänna villkor</NavLink></li>
            <li className={styles.footerInfoLink}><NavLink className={styles.footerLinkInfo} onClick={goUp} to="/cookiepolicy">Cookiepolicy</NavLink></li>
            <li className={styles.footerInfoLink}><NavLink className={styles.footerLinkInfo} onClick={goUp} to="/integritetspolicy">Integritetspolicy</NavLink></li>
          </ul>
          <div className={styles.policy}>
          <a className={styles.footerLogoLink} style={{ backgroundImage: `url(${logo})` , backgroundSize:"cover", width:"420px", height:"60px", display:"block", opacity:".5" }} href="/"> </a>
        </div>
        </nav> */}
      </div>
    </footer>
  );
};

export default Footer;
